<template>
  <div class="js-entry o-entry c-panorama-entry">
    <panorama
    :type="entryData.Json.PanoramaType"
    :src="$xp.settings.baseUrl + 'xpedeo/' + entryData.Image.Url"
    :thumb="entryData.Image.Thumb"
    :hotspots="entryData.Json.ImageMap"
    :startLookAtX="entryData.Json.StartLookAtX"
    :width="entryData.Width"
    :height="entryData.Height"
    @hotspot-click="onHotspotClicked">
    </panorama>
  </div>
</template>

<script>
import entryMixin from '../../mixins/entry'
import Panorama from '../Panorama.vue'
import throttle from '../../util/throttle'
import { handleUrl } from '@/util/router-guards'
export default {
  mixins: [entryMixin],
  components: {
    Panorama
  },
  created () {
    this.onHotspotClicked = throttle(this.onHotspotClicked)
  },
  mounted () {
    // store last panorama
    let pageId = null
    console.log(this.pageData.Class)
    if (this.pageData.Class.includes('Panorama')) {
      pageId = this.pageData.ID
    }
    if (this.pageData.Class === 'XpHome') {
      pageId = parseInt(this.$xp.navigation.activeTabId)
    }
    const homePageId = this.pageData.Homepage ? this.pageData.Homepage.ID : this.$xp.history.lastHomePage.pageId
    this.$xp.history.setLastPanoramaPage(pageId, homePageId)
  },
  methods: {
    onHotspotClicked (hotspot) {
      if (hotspot.href.length > 0) {
        handleUrl(hotspot.href, this.$router)
      }
    }
  }
}
</script>
